<template>
  <div class="message-unassigned full-height">
    <div class="d-flex flex-column full-height full-width">
      <div class="message-unassigned-toolbar d-flex flex-row justify-start ma-2">
        <file-manager
          v-if="files && files.length"
          :selection.sync="files"
          readonly
          ref="file-manager"
          class="mr-4"
        />
        <assign-account
          :from="from"
          @apply="assign"
          class="mr-4"
        />
        <v-btn
          class="mr-2"
          @click="reply()"
        >
          <v-icon>{{$icon('i.Reply')}}</v-icon>
        </v-btn>
        <v-spacer />
        <icon-tooltip
          v-if="messageIsDeleted"
          class="mr-2"
          color="error"
          :icon-name="'i.ExclamationThick'"
          :tooltip-text="$t('t.DocumentIsDeleted')"
        />
        <v-menu
          v-if="canSoftDelete || canRestoreDelete || canHardDelete"
          offset-y
          left
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-2"
              fab
              small
              v-on="on"
            >
              <icon-tooltip
                :icon-name="'i.DotsVertical'"
                :tooltip-text="$t('t.Actions')"
              />
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item
                link
                v-if="canSoftDelete"
              >
                <v-icon class="mr-4">{{softDelete.icon}}</v-icon>
                <v-list-item-content @click="executeAction(softDelete)">{{softDelete.name}}</v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                v-if="canRestoreDelete"
              >
                <v-icon class="mr-4">{{restoreDelete.icon}}</v-icon>
                <v-list-item-content @click="executeAction(restoreDelete)">{{restoreDelete.name}}</v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                v-if="canHardDelete"
              >
                <v-icon class="mr-4">{{hardDelete.icon}}</v-icon>
                <!-- <v-list-item-content @click="executeAction(hardDelete)">{{hardDelete.name}}</v-list-item-content> -->
                <v-list-item-content @click="showConfirmHardDelete = true">{{hardDelete.name}}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <v-card class="main-content pa-4 d-flex flex-column full-height no-overflow">
        <div>
          <div class="d-flex align-baseline">
            <span class="text-caption recipient-type">{{$t('t.From')}}</span>
            <div class="message-from d-flex flex-wrap ml-1">
              <user-chip
                :close="false"
                :user="from"
                disabled-add-contact
              />
            </div>
            <v-spacer />
            <div>
              <span class="nowrap">{{$t('t.ReceivedOn')}} : {{sentOn | datetime}}</span>
            </div>
          </div>
          <div class="d-flex align-baseline">
            <span class="text-caption recipient-type">{{$t('t.To')}}</span>
            <div class="recipients d-flex flex-wrap overflow-y-auto">
              <user-chip
                class="ml-1"
                v-for="(_, idx) in to"
                :key="idx"
                :close="false"
                :attach="false"
                :user="fixUser(_)"
                disabled-add-contact
              />
            </div>
          </div>
          <div class="d-flex align-baseline">
            <span
              v-if="cc.length"
              class="text-caption recipient-type"
            >{{$t('t.CC')}}</span>
            <div
              v-if="cc.length"
              class="recipients d-flex flex-wrap overflow-y-auto"
            >
              <user-chip
                class="ml-1"
                v-for="(_, idx) in cc"
                :key="idx"
                :close="false"
                :attach="false"
                :user="fixUser(_)"
              />
            </div>
          </div>
        </div>

        <div class="d-flex flex-column no-overflow full-height rounded-0">
          <v-textarea
            class="flex-grow-0"
            no-resize
            readonly
            rows="1"
            :label="$t('t.Subject')"
            v-model="message.subject"
          />
          <content-editor
            class="email-editor"
            readonly
            :content="computedMessageContent"
          />
        </div>
      </v-card>
    </div>
    <v-dialog
      v-model="showConfirmHardDelete"
      width="clamp(30%, 400px, 100%)"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text class="d-flex-flex-column">
          {{$t('t.AreYouSure')}}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="executeAction(hardDelete)">
            {{$t('t.Yes')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="showConfirmHardDelete = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import navigation from '@/navigationController'
import Search, { GlobalSearch } from '@/pages/search/controllers'

export default {
  components: {
    AssignAccount: () => import('./assign-account'),
    ContentEditor: () => import('@/components/content-editor'),
    FileManager: () => import('@/components/file-manager'),
    UserChip: () => import('@/components/user-chip'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  activated () {
    this.loadMessage()
  },
  data () {
    return {
      message: {},
      showConfirmHardDelete: false
    }
  },
  computed: {
    actionItem () {
      return {
        id: this.messageId,
        type: 'messages-unassigned',
        extra: { isDeleted: this.messageIsDeleted }
      }
    },
    actions () {
      return Search.getActions(this.actionItem)
    },
    restoreDelete () {
      return this.actions.get('restore-delete-message')
    },
    canRestoreDelete () {
      return this.restoreDelete?.validateRules([this.actionItem])
    },
    softDelete () {
      return this.actions.get('soft-delete-message')
    },
    canSoftDelete () {
      return this.softDelete?.validateRules([this.actionItem])
    },
    hardDelete () {
      return this.actions.get('hard-delete-message')
    },
    canHardDelete () {
      return this.hardDelete?.validateRules([this.actionItem])
    },
    computedMessageContent () {
      // Parse the string as html and get the body's children
      const childElementCount = new DOMParser().parseFromString(this.message.content, 'text/html').body.childElementCount
      // If there is a content and no children, use <pre> tag to preserve text formating
      return (this.message?.content?.length && !childElementCount) ? `<pre>${this.message.content}</pre>` : this.message.content
    },
    messageIsDeleted () {
      return this.message?.message?.isDeleted
    },
    files () {
      return this.message?.attachments
    },
    from () {
      const u = {
        availabilityHours: this.message?.sender?.availabilityHours,
        culture: this.message?.sender?.culture,
        email: this.message?.sender?.eMail ?? null,
        fullname: this.message?.sender?.fullName ?? this.message?.sender?.eMail,
        isAccountContact: this.message?.sender?.isAccountContact,
        isActive: true,
        isAllowPortalAccess: this.message?.sender?.isAllowPortalAccess,
        jobTitle: this.message?.sender?.jobTitle,
        phone1: this.message?.sender?.phone1,
        phone2: this.message?.sender?.phone2,
        title: this.message?.sender?.title,
        userId: this.message?.sender?.potentialSenderId ?? null
      }
      return u
    },
    messageId () {
      return this.$route.params.id
    },
    sentOn () {
      return this.message?.message?.createAt
    },
    cc () {
      return this.message?.recipients?.filter(r => r.type === 1) ?? []
    },
    to () {
      return this.message?.recipients?.filter(r => r.type === 0) ?? []
    }
  },
  methods: {
    fixUser (u) {
      return {
        fullname: u.fullName ?? u.eMail,
        email: u.eMail ?? null
      }
    },
    async assign (assignInfo) {
      const contactId = this.from.userId
      let success = true
      if (assignInfo.contact && assignInfo.contact.email && assignInfo.contact.isAccountContact) {
        if (!contactId) {
          await this.$http()
            .post('/core/v6/account-contacts', assignInfo.contact)
            .catch(e => {
              success = false
              this.$store.dispatch('showErrorSnackbar', e.response.data?.message ? this.$t(e.response.data.message) : undefined)
            })
        } else {
          await this.$http()
            .put(`/core/v6/account-contacts/${contactId}`, assignInfo.contact)
            .catch(e => {
              success = false
              this.$store.dispatch('showErrorSnackbar', e.response.data?.message ? this.$t(e.response.data.message) : undefined)
            })
        }
      }
      if (success) {
        return await this.assignAccount(assignInfo.accountId, assignInfo.contact?.contactGroupId)
      }

      return null
    },
    async assignAccount (accountId, contactGroupId) {
      await this.$http()
        .put(`/core/v6/message/${this.messageId}/assign`, { accountId, contactGroupId })
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Success'))
          navigation.navigateTo(this.messageId, 'messages-inbox', null, true)
        }).catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.response.data.message)
        })
    },
    reply () {
      window.location.href = 'mailto:' + encodeURIComponent(this.from.email) + '?subject=RE: ' + encodeURIComponent(this.message.subject)
    },
    async executeAction (action) {
      action.executeQuick(
        this.actionItem,
        isRevert => {
          if (isRevert) {
            GlobalSearch.activeSearch.refreshResults()
          } else {
            this.loadMessage()
            this.$router.push('/search').catch(e => {
              if (e.name !== 'NavigationDuplicated') {
                throw e
              }
            })
          }
        }
      )
    },
    async loadMessage () {
      const result = await this.$http().get('/core/messages/unassigned/' + this.messageId)
      this.message = result.data
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.full-width
  width 100%

.no-overflow
  overflow-y hidden

.content-area
  min-width 80em
  overflow-y auto

.recipients
  max-height 80px

.recipient-type
  width 36px

.message-unassigned
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap

  .v-card, .v-skeleton-loader
    margin 0.5em

.email-editor
  display flex
  flex-grow 1
</style>
